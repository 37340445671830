import Rails from "@rails/ujs"

Rails.start()

import 'bootstrap'

// our stylesheet
import "stylesheet5/application_landing"
import '@flaticon/flaticon-uicons/css/all/all.css'

import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.splide');

  sliders.forEach((slider) => {
    new Splide(slider, {
      type: 'slide',
      perPage: 1,
      pagination: false,
      gap: '1rem',
      drag: false,
    }).mount();
  });
});
